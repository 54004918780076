import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import React, { HTMLInputTypeAttribute } from 'react';
import { Control, useController } from 'react-hook-form';

interface OwnProps {
  name: string;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  validate?: (value: any) => undefined | string | Promise<string>;
  control?: Control;
}

export type Props = OwnProps & TextFieldProps;

const TextField: React.FunctionComponent<Props> = ({
  name,
  onBlur,
  onChange,
  maxLength,
  minLength,
  min,
  max,
  inputProps,
  error,
  helperText,
  autoFocus,
  validate,
  control,
  type,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    defaultValue: '',
    control,
    rules: {
      min,
      max,
      maxLength,
      minLength,
      validate,
    },
  });

  const onBlurCallback: TextFieldProps['onBlur'] = (event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  };

  const onChangeCallback: TextFieldProps['onChange'] = (event, ...rest) => {
    field.onChange(getEventValue(event, type));
    if (onChange) {
      onChange(event, ...rest);
    }
  };

  const showError = !!fieldState.error;

  return (
    <MuiTextField
      onBlur={onBlurCallback}
      onChange={onChangeCallback}
      value={field.value ?? ''}
      id={name}
      name={field.name}
      inputRef={field.ref}
      inputProps={{
        maxLength,
        minLength,
        min,
        max,
        name,
        ...inputProps,
      }}
      error={error || showError}
      helperText={(showError && fieldState.error.message) || helperText}
      autoFocus={autoFocus}
      type={type}
      {...rest}
    />
  );
};

TextField.displayName = 'TextField';

export default React.memo<Props>(TextField);

function getEventValue(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: HTMLInputTypeAttribute) {
  if (type === 'number') {
    const v = event.target.value;
    return v === '' ? null : +v;
  }

  return event;
}
